export default {
  global: {
    agreementsTopic: 'CP',
    city: 'Bucuresti',
    country: 'Romania',
    langIso: 'ro-RO',
    countryIso: 'ROU',
    xTenant: 'RO',
    storeSpecificXTenant: 'RO',
    loqateApiKey: '',
    pagination: {
      productsPerPage: 24,
    },
    pharmacyTypes: [],
    phoneCode: '+40',
    phoneCodes: ['+40'],
    phoneValidations: ['^7\\d{8}$'],
    storeName: 'Dr.Max',
    validationPhone: '^7\\d{8}$',
    zipLength: 6,

    /**
     * Global features - used across multiple common modules
     */
    advancedAgreementsEnabled: false,
    billingInformationAddressEnabled: false,
    companyBillingAddressEnabled: true,
    competitionServiceTimeBasedEnabled: false,
    crnEnabled: true,
    crnOptional: true,
    fiscalCodeEnabled: false,
    loggedPriceInfoEnabled: true,
    loyaltyCardEnabled: true,
    loyaltyCardPlusPrefixEnabled: false,
    loyaltySavingsEnabled: false,
    multiStoreEnabled: true,
    myAccountCouponsEnabled: false,
    myAccountAmazonEnabled: false,
    myAccountAmazonFromPlacementApi: false,
    myAccountAmazonAnotherOffers: false,
    myAccountOtherOffersButtonEnabled: false,
    newUrlResolverEnabled: true,
    otcFormEnabled: true,
    phoneCodeEnabled: true,
    phoneCodeSelectEnabled: false,
    phoneCodeSentSeparatelyEnabled: true,
    regionSelectEnabled: true,
    savingsAndBenefitsInHeaderEnabled: false,
    skDicEnabled: false,
    splitStreetNumberBeforeSavingEnabled: false,
    blockItemRelatedWarnings: false,
    streetNumberEnabled: false,
    vatIdEnabled: true,
    vatIdRequired: true,

    /**
     * Google Maps API
     * API Keys: https://mydrmax.atlassian.net/wiki/spaces/GLECOM/pages/1600290889/API+keys+-+Google+Maps
     * Supported languages: Subset of ISO 639-1, see https://developers.google.com/maps/faq#languagesupport
     */
    maps: {
      apiKey: 'AIzaSyCckUGG_4uwu9uahpZ7bloHV5xWAHVdXl8',
      interfaceLanguage: 'ro',
      myLocationIcon: 'map-my-location.png',
      defaultLocation: {
        latitude: 44.435021,
        longitude: 26.101110,
      },
    },

    watchdog: {
      stockAlertEnabled: true,
      priceAlertEnabled: true,
      forceLoginEnabled: false,
    },
  },
}
